import Link from 'next/link';
import React, { Component } from 'react';

class Breadcrumb extends Component {
	render() {
		const { title } = this.props;
		return (
			<div className="breadcrumb-section">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-6">
							<div className="page-title">
								<h2>{title}</h2>
							</div>
						</div>
						<div className="col-md-6">
							<nav aria-label="breadcrumb" className="theme-breadcrumb">
								<ol className="breadcrumb">
									<Link
										href={
											`/` // Leave as ID...  URL Slug is dependent in the dataset and not pre-generated yet
										}
										as={
											`/` // Leave as ID...  URL Slug is dependent in the dataset and not pre-generated yet
										}
										passHref>
										<li className="breadcrumb-item">
											<Link href="/">Home</Link>
										</li>
									</Link>

									<li className="breadcrumb-item active" aria-current="page">
										{title}
									</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Breadcrumb;
