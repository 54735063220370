import Breadcrumb from '@src/components/home/breadcrumb';
import CustomSignIn from '@src/components/signin/customsignin';
import { getCustomer } from '@src/utils/customer';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';

const Login = () => {
	const [user, setUser] = useState(null);
	useEffect(() => {
		const customer = getCustomer()?.customer;
		if (customer) {
			setUser(customer);
		}
	}, []);

	return (
		<div>
			<Breadcrumb title={'Login'} />

			<div className="account-login-register">
				<div className="container">
					<div className="login-container">
						<h2>Login</h2>
						<React.Fragment>
							{!user && (
								<>
									<CustomSignIn />
									<div className="new-customer">
										<h4>New Customer?</h4>
										<p>
											Sign up for a free account at our store. Registration is quick and easy. It
											allows you to be able to manage your account. To start please click create
											an account.
										</p>
										<Link href={`/register`} as={`/register`}>
											<a className="btn secondary">
												Create Account <i className="far fa-chevron-right"></i>
											</a>
										</Link>
									</div>
								</>
							)}
							{user && (
								<>
									<div className="welcome-module">
										<p>You are signed in as:</p>
										<div className="inner">
											<div className="user-card">
												<div className="icon">
													<i className="fad fa-user" aria-hidden="true"></i>
												</div>
												<div className="text">
													<h3>
														{user.first_name} {user.last_name}
													</h3>
													<h3>{user.email}</h3>
													<div className="button-container">
														<Link href={`/logout`} as={`/logout`}>
															<a className="button">
																<span>
																	<i
																		className="fad fa-sign-out-alt"
																		aria-hidden="true"></i>{' '}
																	Sign Out
																</span>
															</a>
														</Link>
													</div>
												</div>
											</div>
											<div className="button-container">
												<Link href={`/logout`} as={`/logout`}>
													<a className="button">
														<span>
															<i className="fad fa-sign-out-alt" aria-hidden="true"></i>{' '}
															Sign Out
														</span>
													</a>
												</Link>
											</div>
										</div>
									</div>

									<Link href={`/dashboard`} as={`/dashboard`}>
										<a className="btn mt-2">Dashboard</a>
									</Link>
								</>
							)}
						</React.Fragment>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
