// Concepts from https://github.com/richardzcode/Journal-AWS-Amplify-Tutorial/blob/master/step-08/journal/src/components/auth/JSignIn.jsx

import * as customerActions from '@src/redux/actions/customer';
import Link from 'next/link';
import React, { Component } from 'react';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

export class CustomSignIn extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.checkLogin = this.checkLogin.bind(this);
		this.state = {
			error: '',
			IsSigningIn: false,
			inputs: {
				username: '',
				password: '',
			},
		};
		this.validator = new SimpleReactValidator({
			autoForceUpdate: this,
			locale: 'en',
		});
	}

	async checkLogin(username, password) {
		try {
			const { cart } = this.props;

			var cartId = null;
			if (cart.bigCommerceCart) {
				cartId = cart.bigCommerceCart.id;
			}
			var user = await this.props.login({
				username: username,
				password: password,
				redirectUrl: '/dashboard',
				cartId: cartId,
			});
		} catch (error) {
			{
				toast.error(`There was an error while logging in, please check email and password and try again!`);

				// TODO: This would be nice place to log errors to some external service
			}
		}
		if (user != undefined) {
			analytics.track('Logged in', {
				userId: user.id,
				action: 'track',
				event: 'Logged In',
				properties: {
					category: 'Account',
				},
			});

			analytics.identify(user.id, {
				email: user.email,
				name: `${user.first_name ? user.first_name : ''} ${user.last_name ? user.last_name : ''}`,
			});
		}
		this.setState({ error: null, IsSigningIn: false });
	}

	handleSubmit() {
		if (this.validator.fieldValid('username') && this.state.inputs.password) {
			// FIXME: Strange hack where pre-populated username and password from Chrome would not validate password on first click.
			const { username, password } = this.state.inputs;
			this.setState({ error: null, IsSigningIn: true });
			this.checkLogin(username, password);
		} else {
			this.validator.showMessages();
		}
	}

	onChange = (e, prop) => {
		this.setState({ inputs: { ...this.state.inputs, [prop]: e.target.value } });
	};

	render() {
		return (
			<form className="login-form" onSubmit={this.handleSubmit}>
				<div className="form-group">
					<label htmlFor="email">Your Email (required)</label>
					<input
						type="text"
						className="form-control"
						id="username"
						placeholder="Your Email"
						required={this.state.inputs?.username === ''}
						onChange={e => this.onChange(e, 'username')}
					/>
				</div>
				{this.validator.message('username', this.state.inputs.username, 'required|email')}
				<div className="form-group">
					<label htmlFor="review">Your Password (required)</label>
					<input
						type="password"
						className="form-control"
						id="password"
						placeholder="Your Password"
						required={this.state.inputs?.password === ''}
						onChange={e => this.onChange(e, 'password')}
					/>
				</div>

				{this.validator.message('password', this.state.inputs.password, 'required|string')}

				{this.state.IsSigningIn ? (
					<button className="btn" type="button">
						<i className="fas fa-spinner fa-spin"></i> Logging In
					</button>
				) : (
					<button
						className={`btn ${this.props.secondary ? 'secondary' : ''}`}
						type="submit"
						onClick={this.handleSubmit}>
						<i className="fad fa-sign-in"></i> Login
					</button>
				)}
				<Link href={`/forgot-password`} as={`/forgot-password`}>
					<a className="forgot-password">Forgot Your Password?</a>
				</Link>
			</form>
		);
	}
}

const mapStateToProps = state => ({
	user: state.customer,
	cart: state.cartlist,
});

const mapDispatchToProps = dispatch => ({
	login: payload => dispatch(customerActions.login(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomSignIn);
